import { SpeciesReference } from '@agria/theme-qab/src/types/global';

export const speciesReference: SpeciesReference = {
  'f4372701-68d2-4459-bdba-b3be753e80d4': {
    name: 'Dog',
    displayName: 'dog',
    litterName: 'Puppy',
    crossBreedId: 'af578e96-eb84-48b9-8151-0557e0b31cbd',
  },
  'c2597178-071f-4940-825d-4d7351b5ffa4': {
    name: 'Cat',
    displayName: 'cat',
    litterName: 'Kitten',
    crossBreedId: 'b14a06c6-d706-4537-9a5b-87287d5cb5c0',
  },
  '636b067c-dd3e-480d-87e7-d59df08c221c': {
    name: 'Equine',
    litterName: 'Foal',
    displayName: 'horse',
  },
};
